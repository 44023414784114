import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

class GeneralHeader extends React.Component {
  render() {
    return (
      <>
        <div
          className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
          style={{
            minHeight: "300px",
            /*backgroundImage:
              "url(" + (this.props.image || require("assets/img/theme/profile-cover.jpg")) + ")",*/
            backgroundSize: "cover",
            backgroundPosition: "center center"
          }}
        >
          <span className="mask bg-gradient-default opacity-8" />
          <Container className="align-items-center" fluid>
            <Row>
              <Col lg="7" md="10">
                <h1 className="display-2 text-white">{this.props.title || "Title"}</h1>
                <p className="text-white mt-0 mb-5">
                  {this.props.description || "Descrizione"}
                </p>
                { this.props.actions ? this.props.actions : null }
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default GeneralHeader;
