//REGEX
const alphaReg =  /^[A-Za-z ]+$/;
const alphaNumReg =  /^[A-Za-z0-9 ]+$/;
const numReg = /^[0-9]+$/;
const emailReg = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])+$/;

module.exports = {
	validate: (data, topology, state) => {
		let validations = topology.states[state].validations;
		let errors = [];
		Object.keys(validations).forEach(field => {

			let value = data[field];

			// IS REQUIRED ?
			if (eval(validations[field].required) === true && (!value || value === false || value === 0)) {
				errors.push({field: field, property: 'required', message: "Il campo è obbligatorio."});
				return errors;
			}

			// IS THE RIGHT TYPE ?
			if (value != "" && value && validations[field].type)
				switch (validations[field].type) {
					case 'alpha':
						if(!alphaReg.test(value))
							errors.push({
								field: field,
								property: 'type',
								message: "Sono ammesse soltanto lettere."
							});
						break;
					case 'alphanum':
						if(!alphaNumReg.test(value))
							errors.push({
								field: field,
								property: 'type',
								message: "Non sono ammessi caratteri speciali."
							});
						break;
					case 'num':
						if(!numReg.test(value))
							errors.push({
								field: field,
								property: 'type',
								message: "Sono ammessi soltanto numeri."
							});
						break;
					case 'enum':
						if(!validations[field].select.includes(value))
							errors.push({
								field: field,
								property: 'type',
								message: "Valore non ammesso."
							});
						break;
					case 'checkbox':
						if(!["s", "n", "y", "n", "on", "off", "1", "0", 1, 0, true, false].includes(value))
							errors.push({
								field: field,
								property: 'type',
								message: "Valore non ammesso."
							});
						break;
					case 'email':
						if(!emailReg.test(value))
							errors.push({field: field, property: 'type', message: "Email non valida."});
						break;
					case 'any':
						break;
					case 'custom':
						if(!(validations[field].rule && (eval(validations[field].rule))))
							errors.push({
								field: field,
								property: 'type'
							});
						break;
				}
		});

		if(errors)
			return errors;
		else
			return true;
	}
};