import React, {Component} from "react";

import {
	Card,
	CardFooter,
	ListGroup,
	ListGroupItem,
	Container,
	Row,
	Button, Modal, ModalHeader, ModalBody, CardTitle, FormGroup, CustomInput, ModalFooter
} from "reactstrap";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faKeyboard} from "@fortawesome/free-solid-svg-icons/faKeyboard";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import {faCheckSquare} from "@fortawesome/fontawesome-free-solid";
import {faCalendarCheck} from "@fortawesome/free-solid-svg-icons/faCalendarCheck";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";

import GeneralHeader from "../../components/Headers/GeneralHeader.js";
import AddFieldDialog from "./AddFieldDialog";
import ApiService from "../../services/classes/Api";
import AdministrationApiService from "../../services/Administration";
import Col from "reactstrap/es/Col";
import CardHeader from "reactstrap/es/CardHeader";
import Sugar from "sugar";
import {Slide, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {faEdit, faEye} from "@fortawesome/free-solid-svg-icons";
import {InputLabel, MenuItem, Select, TextField} from "@material-ui/core";

class UsersSettings extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			users: [],
			topologies: []
		};
	}

	componentDidMount() {
		this.loadData()
	}

	loadData() {
		ApiService.graph(`
      {
        authors {
          _id
          name
          surname
          email
          type
          permissions {
            type
            record_type
          }
        } 
        
        topologies {
        	_id
        	name
        	shape
        }
      }
    `).then(response => {
			this.setState({ users: response.data.data.authors, topologies: response.data.data.topologies })
		}).catch(e => console.error(e));
	}

	addPermission(user_id, permission) {
		AdministrationApiService.addUserPermission(user_id, permission).then(response => {
			if(response.status === 200) {

				toast("Permesso aggiunto correttamente.", {
					transition: Slide,
					closeButton: true,
					autoClose: 2000,
					position: 'bottom-center',
					type: 'success'
				});

				this.loadData();

			} else {

				toast("Errore. Riprova più tardi", {
					transition: Slide,
					closeButton: true,
					autoClose: 2000,
					position: 'bottom-center',
					type: 'danger'
				});

			}
		})
	}

	removePermission(user_id, permission) {
		AdministrationApiService.removeUserPermission(user_id, permission).then(response => {
			if(response.status === 200) {

				toast("Permesso rimosso correttamente.", {
					transition: Slide,
					closeButton: true,
					autoClose: 2000,
					position: 'bottom-center',
					type: 'success'
				});

				this.loadData();

			} else {

				toast("Errore. Riprova più tardi", {
					transition: Slide,
					closeButton: true,
					autoClose: 2000,
					position: 'bottom-center',
					type: 'danger'
				});

			}
		})
	}

	addUser(user) {
		ApiService.addUser(user).then(response => {
			if(response.status === 200) {

				toast("Permesso rimosso correttamente.", {
					transition: Slide,
					closeButton: true,
					autoClose: 2000,
					position: 'bottom-center',
					type: 'success'
				});

				this.loadData();

			} else {

				toast("Errore. Riprova più tardi", {
					transition: Slide,
					closeButton: true,
					autoClose: 2000,
					position: 'bottom-center',
					type: 'danger'
				});

			}
		}).catch(e => (
			toast("Errore. Riprova più tardi", {
				transition: Slide,
				closeButton: true,
				autoClose: 2000,
				position: 'bottom-center',
				type: 'danger'
			})
		))
	}

	render() {

		const { topologies } = this.state;

		return (
			<>
				<GeneralHeader
					title={"Gestione utenti"}
					description={"Gestisci qui le utenze che hanno accesso ai dati, ed i permessi che hanno su di essi"}
					image={""}
					actions={<AddUserDialog confirmCallback={(user) => this.addUser(user)} button={<Button outline className="mr-2 border-0 btn-transition btn-block" color="info">Aggiungi utente</Button>} title={`Crea nuovo utente`} />}
				/>
				<Container className="mt--7" fluid>
					<Row>
						{this.state.users.map(u => {
							return (
								<Col key={u._id} md="12" lg="6" xl="4" xxl="3">
									<Card className="card-shadow-primary card-border mb-3">
										<CardHeader style={{textAlign: "center"}}>
											<h2>{ u.name } { u.surname }</h2>
											<h4>{ u.email }</h4>
										</CardHeader>
										<div className="scroll-area-sm" style={{padding: "0 0.5em"}}>
											<UserPermissionsList user={u} removePermission={(user_id, permission) => this.removePermission(user_id, permission)}/>
										</div>
										<CardFooter className="text-center d-block">
											<Button outline className="mr-2 border-0 btn-transition" color="danger">Elimina</Button>
											<AddPermissionDialog confirmCallback={(permission) => this.addPermission(u._id, permission)} button={<Button outline className="mr-2 border-0 btn-transition btn-block" color="success">Aggiungi Permesso</Button>} title={`Aggiungi Permessi a ${u.name}`} topologies={topologies} />
										</CardFooter>
									</Card>
								</Col>
							);
						})}
					</Row>
				</Container>
			</>
		);
	}
}

class AddUserDialog extends Component {

	state = {
		name: "",
		surname: "",
		type: "",
		modal: false
	};

	constructor(props) {
		super(props);
		this.toggle = this.toggle.bind(this);
		this.confirm = this.confirm.bind(this);
	}

	toggle() {
		this.setState(previousState => ({modal: !previousState.modal}) )
	}

	confirm() {
		const { name, surname, type, email, password } = this.state;
		this.props.confirmCallback({ name, surname, type, email, password });
	}

	render() {

		const userTypes = [
			{ name: "user", label: "Utente standard" },
			{ name: "developer", label: "Sviluppatore" },
			{ name: "admin", label: "Amministratore" }
		];

		const { name, surname, type, email, password, passwordConfirm } = this.state;

		return (
			<React.Fragment>
				<span className="d-inline-block">
					<span onClick={this.toggle}> {this.props.button}</span>
						<Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
      	    	<ModalHeader toggle={this.toggle}>{ this.props.title }</ModalHeader>
	    	    	<ModalBody>
								<Card body className="card-shadow-primary border mb-3">
								 <Row form>
									 <Col md={12}>
										 <FormGroup>
											 <InputLabel>Tipologia utente</InputLabel>
											 <Select onChange={(type) => this.setState({ type: type.target.value })} value={type} fullWidth>
												 {userTypes.map(t => <MenuItem value={t.name}>{t.label}</MenuItem>)}
											 </Select>
										 </FormGroup>
									 </Col>
									 <Col md={12}>
										 <FormGroup>
											 <TextField id="standard-basic" label="Nome" onChange={(name) => this.setState({ name: name.target.value })} value={name} name={"name"} id={"field_type"} fullWidth />
										 </FormGroup>
									 </Col>
									 <Col md={12}>
										 <FormGroup>
											 <TextField id="standard-basic" label="Cognome" onChange={(surname) => this.setState({ surname: surname.target.value })} value={surname} name={"surname"} id={"field_type"} fullWidth />
										 </FormGroup>
									 </Col>
									 <Col md={12}>
										 <FormGroup>
											 <TextField id="standard-basic" label="Email" onChange={(email) => this.setState({ email: email.target.value })} value={email} name={"email"} id={"field_type"} fullWidth />
										 </FormGroup>
									 </Col>
									 <Col md={12}>
										 <FormGroup>
											 <TextField id="standard-basic" type={"password"} label="Password" onChange={(password) => this.setState({ password: password.target.value })} value={password} name={"password"} id={"field_type"} fullWidth />
										 </FormGroup>
									 </Col>
									 <Col md={12}>
										 <FormGroup>
											 <TextField id="standard-basic" type={"password"} label="Conferma Password" onChange={(passwordConfirm) => this.setState({ passwordConfirm: passwordConfirm.target.value })} value={passwordConfirm} name={"passwordConfirm"} id={"field_type"} fullWidth />
										 </FormGroup>
									 </Col>
								 </Row>

		  	    	  </Card>
	    	    	</ModalBody>
      	    	<ModalFooter>
      	    	  <Button outline color="danger" className={"border-0 btn-transition"} onClick={this.toggle}> Annulla </Button>
      	    	  <Button color="success" onClick={this.confirm}> Aggiungi </Button>
      	    	</ModalFooter>
      	   </Modal>
				</span>
			</React.Fragment>
		)
	}
};

class AddPermissionDialog extends Component {

	state = {
		record_type: "",
		type: "",
		modal: false
	};

	constructor(props) {
		super(props);
		this.toggle = this.toggle.bind(this);
		this.confirm = this.confirm.bind(this);
	}

	toggle() {
		this.setState(previousState => ({modal: !previousState.modal}) )
	}

	confirm() {
		const { record_type, type } = this.state;
		this.props.confirmCallback({ record_type, type });
	}

	render() {

		const userActions = [
			{ name: "read", label: "Lettura" },
			{ name: "write", label: "Scrittura" }
		];

		const { record_type, type } = this.state;

		return (
			<React.Fragment>
				<span className="d-inline-block">
					<span onClick={this.toggle}> {this.props.button}</span>
						<Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
      	    	<ModalHeader toggle={this.toggle}>{ this.props.title }</ModalHeader>
	    	    	<ModalBody>
								<Card body className="card-shadow-primary border mb-3">

								 <Row form>
									 <Col md={12}>
										 <FormGroup>
											 <InputLabel>Azione</InputLabel>
											 <Select value={type} onChange={(type) => this.setState({type: type.target.value})} fullWidth>
												 {userActions.map(a => <MenuItem value={a.name}>{a.label}</MenuItem>)}
											 </Select>
										 </FormGroup>
									 </Col>
									 <Col md={12}>
										 <FormGroup>
											 <InputLabel>Oggetto</InputLabel>
											 <Select value={record_type} onChange={(record_type) => this.setState({record_type: record_type.target.value})} fullWidth>
												 {this.props.topologies.map(a => <MenuItem value={a.name}>{a.name}</MenuItem>)}
											 </Select>
										 </FormGroup>
									 </Col>
								 </Row>

		  	    	  </Card>
	    	    	</ModalBody>
      	    	<ModalFooter>
      	    	  <Button outline color="danger" className={"border-0 btn-transition"} onClick={this.toggle}> Annulla </Button>
      	    	  <Button color="success" onClick={this.confirm}> Aggiungi </Button>
      	    	</ModalFooter>
      	   </Modal>
				</span>
			</React.Fragment>
		)
	}
};

const UserPermissionsList = (props) => {

	const { user, removePermission } = props;
	const { permissions } = user;

	return (
		<ListGroup>
			{ permissions.map(p => (
				<ListGroupItem>
					<div className="widget-content p-0">
						<div className="widget-content-wrapper">
							<div className="widget-content-left center-elem mr-2">
								{p.type == "read" ? <FontAwesomeIcon icon={faEye} size="1x" />  : null}
								{p.type == "write" ? <FontAwesomeIcon icon={faEdit} size="1x" /> : null}
							</div>
							<div className="widget-content-left">
								<div className="widget-heading">
									{ p.record_type }
								</div>
							</div>
							<div className="widget-content-right widget-content-actions">
								<Button size="sm" onClick={() => removePermission(user._id, { type: p.type, record_type: p.record_type })} className="btn-icon btn-icon-only" color="danger">
									<FontAwesomeIcon icon={faTrash} />
								</Button>
							</div>
						</div>
					</div>
				</ListGroupItem>
			)) }
		</ListGroup>
	)
};

export default UsersSettings;
