import React from "react";
import Icon from "../../assets/img/loading.svg";
import {CircularProgress} from "@material-ui/core";

function Loading() {
	return (
		<div style={{width: "100%", height:"100vh", display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center"}}>
			<CircularProgress color={"primary"} />
		</div>
	);
}

export default Loading;
