import ApiService from "../classes/Api";
import AuthService from "../classes/Auth";
import cookies from "react-cookies";

export default class UserService {

	static isLogged() {
		if(cookies.load("access_token", {path: "/"})) {
			return this._tryLogin(cookies.load("access_token", {path: "/"}));
		} else {
			return new Promise((resolve, reject) => {
				resolve(AuthService.isAuthenticated());
			})
		}
	}

	static login(email, password) {
		return new Promise((resolve, reject) => {
			ApiService.login(email, password).then(response => {
				if(response.data.response === "success" && response.status === 200) {
					AuthService.authenticate(response.data.access_token, response.data.user);
					resolve();
				} else {
					reject(false);
				}
			}).catch(e => reject(false));
		});
	}

	static loginWithToken(token) {
		return new Promise((resolve, reject) => {
			ApiService.checkToken(token).then(response => {
				if(response.data.response === "success") {
					AuthService.authenticate(token, response.data.user);
					resolve(AuthService.isAuthenticated());
				} else {
					resolve(false);
				}
			});
		})
	}

	static logout() {
		AuthService.logout()
	}

	static getUser() {
		return AuthService.getUser();
	}

	static _tryLogin(token) {
		return this.loginWithToken(token);
	}

}