import React, {Component} from 'react';
import {
	Badge, Card, CardFooter,
	CardHeader, DropdownItem, DropdownMenu,
	DropdownToggle,
	Media, Pagination, PaginationItem, PaginationLink,
	Progress,
	Table,
	UncontrolledDropdown,
	UncontrolledTooltip
} from "reactstrap";
import {CircularProgress} from "@material-ui/core";

export default class DataTableComponent extends Component {

	render() {

		const { title, data, schema, onClick, actions, loading, page, total, lastPage } = this.props;

		const Cols = () => schema.map(c => <th scope="col">{c.name}</th>);
		const Rows = () => data.map(r => (
			<tr onClick={() => onClick(r._id)}>
				{schema.map(c => <td>{c.selector(r)}</td>)}
			</tr>
		));

		return (
			<Card className="shadow">
				<CardHeader className="border-0 d-flex" style={{alignItems: "center", justifyContent: "space-between"}}>
					<h3 className="mb-0">{title}</h3>
					<span>{actions}</span>
				</CardHeader>
				{ loading ? <div style={{textAlign: "center"}}><CircularProgress color={"primary"} /></div> : (

					<Table className="align-items-center table-flush" responsive>
						<thead className="thead-light">
							<tr>
								<Cols />
							</tr>
						</thead>
						<tbody>
							<Rows />
						</tbody>
					</Table>

				) }
				<CardFooter className="py-4">
					<nav aria-label="...">
						<Pagination className="pagination justify-content-end mb-0" listClassName="justify-content-end mb-0">
							<PaginationItem className={ page === 1 ? `disabled` : ""}>
								<PaginationLink href="#pablo" onClick={e => this.props.setPage(page - 1)} tabIndex="-1">
									<i className="fas fa-angle-left" />
									<span className="sr-only">Precedente</span>
								</PaginationLink>
							</PaginationItem>
							<PaginationItem className="active">
								<PaginationLink
									href="#pablo"
									onClick={e => e.preventDefault()}
								>
									{ page }
								</PaginationLink>
							</PaginationItem>
							<PaginationItem className={ page === lastPage ? `disabled` : ""}>
								<PaginationLink href="#" onClick={e => this.props.setPage(page + 1)}>
									<i className="fas fa-angle-right" />
									<span className="sr-only">Successiva</span>
								</PaginationLink>
							</PaginationItem>
						</Pagination>
					</nav>
				</CardFooter>
			</Card>
		)
	}

}