import React from "react";
import Row from "reactstrap/es/Row";
import Col from "reactstrap/es/Col";
import {ControlledEditor} from "@monaco-editor/react";


const codeEditorOptions = {
	"acceptSuggestionOnCommitCharacter": true,
	"acceptSuggestionOnEnter": "on",
	"accessibilitySupport": "auto",
	"autoIndent": true,
	"automaticLayout": true,
	"codeLens": true,
	"colorDecorators": true,
	"contextmenu": true,
	"cursorBlinking": "blink",
	"cursorSmoothCaretAnimation": false,
	"cursorStyle": "line",
	"disableLayerHinting": false,
	"disableMonospaceOptimizations": false,
	"dragAndDrop": false,
	"fixedOverflowWidgets": false,
	"folding": true,
	"foldingStrategy": "auto",
	"fontLigatures": false,
	"formatOnPaste": false,
	"formatOnType": false,
	"hideCursorInOverviewRuler": false,
	"highlightActiveIndentGuide": true,
	"links": true,
	"mouseWheelZoom": false,
	"multiCursorMergeOverlapping": true,
	"multiCursorModifier": "alt",
	"overviewRulerBorder": true,
	"overviewRulerLanes": 2,
	"quickSuggestions": true,
	"quickSuggestionsDelay": 100,
	"readOnly": false,
	"renderControlCharacters": false,
	"renderFinalNewline": true,
	"renderIndentGuides": true,
	"renderLineHighlight": "all",
	"renderWhitespace": "none",
	"revealHorizontalRightPadding": 30,
	"roundedSelection": true,
	"rulers": [],
	"scrollBeyondLastColumn": 0,
	"scrollBeyondLastLine": false,
	"selectOnLineNumbers": true,
	"selectionClipboard": true,
	"selectionHighlight": true,
	"showFoldingControls": "mouseover",
	"smoothScrolling": false,
	"suggestOnTriggerCharacters": true,
	"wordBasedSuggestions": true,
	"wordSeparators": "~!@#$%^&*()-=+[{]}|;:'\",.<>/?",
	"wordWrap": "off",
	"wordWrapBreakAfterCharacters": "\t})]?|&,;",
	"wordWrapBreakBeforeCharacters": "{([+",
	"wordWrapBreakObtrusiveCharacters": ".",
	"wordWrapColumn": 80,
	"wordWrapMinified": true,
	"wrappingIndent": "none"
};

export default class WebIDE extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			code: ""
		}
	}

	setCode(code) {
		this.setState({ code })
	}

	render() {

		const { code } = this.state;

		return (
			<>
				<ControlledEditor
					height="100%"
					language="javascript"
					value={code}
					style={{padding: "1em 0", background: "#1E1E1E"}}
					onChange={(ev, value) => this.setCode(value)}
					theme={"vs-dark"}
					options={codeEditorOptions}
				/>
			</>
		)
	}

}