import React, {Component} from "react";

import {
  Card,
  CardFooter,
  ListGroup,
  ListGroupItem,
  Container,
  Row, Modal, ModalHeader, ModalBody, FormGroup, ModalFooter, Button
} from "reactstrap";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faKeyboard} from "@fortawesome/free-solid-svg-icons/faKeyboard";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import {faCheckSquare} from "@fortawesome/fontawesome-free-solid";
import {faCalendarCheck} from "@fortawesome/free-solid-svg-icons/faCalendarCheck";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";

import GeneralHeader from "../../components/Headers/GeneralHeader.js";
import AddFieldDialog from "./AddFieldDialog";
import ApiService from "../../services/classes/Api";
import AdministrationApiService from "../../services/Administration";
import Col from "reactstrap/es/Col";
import CardHeader from "reactstrap/es/CardHeader";
import Sugar from "sugar";
import {Slide, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {faCube, faEdit} from "@fortawesome/free-solid-svg-icons";
import {InputLabel, MenuItem, Select, TextField, Button as MaterialButton, IconButton} from "@material-ui/core";

class SchemaSettings extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      topologies: []
    };
    this._onDragEnd = this._onDragEnd.bind(this);
  }

  componentDidMount() {
    this.loadData()
  }

  loadData() {
    ApiService.graph(`
      {
        topologies {
          _id
          name
          label
          plural_label
          shape
          states
          collections
        } 
      }
    `).then(response => {
      this.setState({ topologies: response.data.data.topologies })
    }).catch(e => console.error(e));
  }

  createTopology(topology) {
    AdministrationApiService.createTopology(topology).then(response => {
      toast(`Oggetto ${topology.label} creato.`, {
        transition: Slide,
        closeButton: true,
        autoClose: 2000,
        position: 'bottom-center',
        type: 'success'
      });

      this.loadData();
    }).catch(e => {
      console.log(e)
      toast("Si è verificato un problema, riprova più tardi.", {
        transition: Slide,
        closeButton: true,
        autoClose: 2000,
        position: 'bottom-center',
        type: 'error'
      })
    });
  }

  editTopology(topology) {
    AdministrationApiService.editTopology(topology._id, topology).then(response => {
      toast("Archetipo modificato correttamente.", {
        transition: Slide,
        closeButton: true,
        autoClose: 2000,
        position: 'bottom-center',
        type: 'success'
      });

      this.loadData();

    }).catch(e => toast("Si è verificato un problema, riprova più tardi.", {
      transition: Slide,
      closeButton: true,
      autoClose: 2000,
      position: 'bottom-center',
      type: 'error'
    }));
  }

  addField(oid, field) {
    console.log(field)
    let topologies = this.state.topologies;
    const topology = topologies.filter(t => t._id === oid)[0];
    topologies = Sugar.Array(topologies).remove(topology);
    topology.shape[field.key] = field;
    topologies.push(topology);
    AdministrationApiService.addTopologyField(oid, field).then(response => {
      toast("Archetipo modificato correttamente.", {
        transition: Slide,
        closeButton: true,
        autoClose: 2000,
        position: 'bottom-center',
        type: 'success'
      });
      this.loadData();
    }).catch(e => {
      toast("Si è verificato un problema, riprova più tardi.", {
        transition: Slide,
        closeButton: true,
        autoClose: 2000,
        position: 'bottom-center',
        type: 'error'
      });
    });
  }

  removeField(oid, key) {
    AdministrationApiService.removeTopologyField(oid, key).then(response => {
      toast("Archetipo modificato correttamente.", {
        transition: Slide,
        closeButton: true,
        autoClose: 2000,
        position: 'bottom-center',
        type: 'success'
      });
      this.loadData();
    }).catch(e => {
      toast("Si è verificato un problema, riprova più tardi.", {
        transition: Slide,
        closeButton: true,
        autoClose: 2000,
        position: 'bottom-center',
        type: 'error'
      });
    });
  }

  _onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    let topologies = this.state.topologies;
    const topology = topologies.filter(t => t.name === result.source.droppableId)[0];

    const fields = reorder(
      topology.shape,
      result.source.index,
      result.destination.index
    );

    topologies[topologies.indexOf(topology)].shape = fields;

    this.setState({
      topologies: topologies
    });

    this.editTopology(topology)
  }

  render() {
    return (
      <>
        <GeneralHeader
          title={"Schema Dati"}
          description={"Modifica qui lo schema dei dati a cui fanno riferimento tutti i record gestiti dalla piattaforma"}
          image={""}
          actions={<AddTopologyDialog confirmCallback={(topology) => this.createTopology(topology)} button={<Button outline className="mr-2 border-0 btn-transition btn-block" color="info">Aggiungi oggetto</Button>} title={`Crea nuovo oggetto`} />}
        />
        <Container className="mt--7" fluid>
          <Row>
            {this.state.topologies.map(t => {
              return (
                <Col key={t.name} md="12" lg="6" xl="4" xxl="3">
                  <Card className="card-shadow-primary card-border mb-3">
                    <CardHeader style={{display: "flex", alignItems: "center", justifyContent: "space-between", margin: 0}}>
                      <h2 style={{margin: 0}}>{ t.plural_label }</h2>
                      <AddTopologyDialog
                        confirmCallback={(topology) => this.editTopology(topology)}
												data={t}
                        button={<MaterialButton variant={"outlined"} size={"small"}>Modifica</MaterialButton>}
                        title={`Modifica oggetto`}
                      />
                    </CardHeader>
                    <div className="scroll-area-sm" style={{padding: "0.5em"}}>
                      <DragAndDropList topology={t} onDragEnd={this._onDragEnd} removeField={(field, key) => this.removeField(field, key)} />
                    </div>
                    <CardFooter className="text-center d-block">
                      <Button outline className="mr-2 border-0 btn-transition" color="danger">Elimina</Button>
                      <AddFieldDialog topologies={this.state.topologies} confirmCallback={(field) => this.addField(t._id, field)} button={<Button outline className="mr-2 border-0 btn-transition btn-block" color="success">Aggiungi campo</Button>} title={`Aggiungi un campo all'archetipo ${t.label}`} />
                    </CardFooter>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </Container>
      </>
    );
  }
}

const DragAndDropList = (props) => {

  const t = props.topology;
  const { onDragEnd, removeField } = props;

  return (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={t.name}>
          {(provided, snapshot) => (
            <div id={`fieldsEditor_${t.name}`} {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver, `fieldsEditor_${t.name}`)}>
              <ListGroup>
                { t.shape ?
                  Object.keys(t.shape).map((key, index) => {
                    const field = t.shape[key];

                    return (
                      <Draggable key={key} draggableId={key} index={index}>
                        {(provided, snapshot) => (
                          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
                            <ListGroupItem>
                              <div className="widget-content p-0">
                                <div className="widget-content-wrapper d-flex" style={{justifyContent: "space-between"}}>
                                  <div className="widget-content-left center-elem mr-2" style={{display: "flex", alignItems: "center"}}>
                                    { field.type === "input" ? <FontAwesomeIcon icon={faKeyboard} size="1x" /> : null }
                                    { field.type === "select" ? <FontAwesomeIcon icon={faList} size="1x" /> : null }
                                    { field.type === "checkbox" ? <FontAwesomeIcon icon={faCheckSquare} size="1x" /> : null }
                                    { field.type === "date" ? <FontAwesomeIcon icon={faCalendarCheck} size="1x" /> : null }
                                    { field.type === "topology" ? <FontAwesomeIcon icon={faCube} size="1x" /> : null }
                                  </div>
                                  <div className="widget-content-left" style={{display: "flex", alignItems: "center"}}>
                                    <div className="widget-heading">
                                      { field.label }
                                    </div>
                                  </div>
                                  <div
                                    className="widget-content-right widget-content-actions">
                                    <Button size="sm" onClick={() => removeField(t._id, key)} className="btn-icon btn-icon-only" color="danger">
                                      <FontAwesomeIcon icon={faTrash} />
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </ListGroupItem>
                          </div>
                        )}
                      </Draggable>
                    )
                  })
                  :
                  <ListGroupItem>
                    <div className="widget-content p-0">
                      <div className="widget-content-wrapper">
                        <div className="widget-content-left">
                          <div className="widget-heading">
                            Nessun campo
                          </div>
                        </div>
                      </div>
                    </div>
                  </ListGroupItem>
                }
              </ListGroup>
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

const getListStyle = (isDraggingOver, droppableId) => {
  return ({
    minHeight: isDraggingOver ? document.getElementById(droppableId).offsetHeight : null
  });
};

const getItemStyle = (isDragging, draggableStyles) => {
  return ({
    ...draggableStyles,
    borderLeftColor: isDragging ? '#444054' : 'initial',
    marginBottom: '0.3em'
  })
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(Object.keys(list).map(k => {
    const obj = list[k];
    obj.key = k;
    return obj;
  }));
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  let result2 = {};
  result.forEach(f => {
    const key = f.key;
    delete f.key;
    result2[key] = f;
  });

  return result2;
};

class AddTopologyDialog extends Component {

  state = {
    name: "",
    label: "",
    plural_label: "",
		states: {},
    modal: false
  };

  constructor(props) {
    super(props);

    if(props.data) {
      this.state = {
        name: props.data.name,
        label: props.data.label,
        plural_label: props.data.plural_label,
				states: props.data.states
      }
    }

    this.toggle = this.toggle.bind(this);
    this.confirm = this.confirm.bind(this);
  }

  toggle() {
    this.setState(previousState => ({modal: !previousState.modal}) )
  }

  confirm() {
    const { name, label, plural_label, states } = this.state;
    this.props.confirmCallback({ name, label, plural_label });
    this.toggle();
  }

  render() {

    const { name, label, plural_label } = this.state;
    const states = Object.keys(this.state.states).map(k => ({ name: k, ...this.state.states[k] }));

    return (
      <React.Fragment>
				<span className="d-inline-block">
					<span onClick={this.toggle}> {this.props.button}</span>
						<Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
      	    	<ModalHeader toggle={this.toggle}>{ this.props.title }</ModalHeader>
	    	    	<ModalBody>
								<InputLabel>Generale</InputLabel>
								<Card body className="card-shadow-primary border mb-3">
								 <Row form>
									 <Col md={12}>
										 <FormGroup>
											 <TextField id="standard-basic" label="Slug" onChange={(name) => this.setState({ name: name.target.value })} value={name} name={"name"} id={"field_type"} fullWidth />
										 </FormGroup>
									 </Col>
									 <Col md={12}>
										 <FormGroup>
											 <TextField id="standard-basic" label="Label" onChange={(label) => this.setState({ label: label.target.value })} value={label} name={"surname"} id={"field_type"} fullWidth />
										 </FormGroup>
									 </Col>
									 <Col md={12}>
										 <FormGroup>
											 <TextField id="standard-basic" label="Label plurale" onChange={(plural_label) => this.setState({ plural_label: plural_label.target.value })} value={plural_label} name={"email"} id={"field_type"} fullWidth />
										 </FormGroup>
									 </Col>
								 </Row>
		  	    	  </Card>
								<InputLabel>Stati</InputLabel>
								<Card body className="card-shadow-primary border mb-3">
								 <ListGroup>
									 { states.map(s => (
										 <ListGroupItem>{s.title}</ListGroupItem>
									 )) }
								 </ListGroup>
		  	    	  </Card>
	    	    	</ModalBody>
      	    	<ModalFooter>
      	    	  <Button outline color="danger" className={"border-0 btn-transition"} onClick={this.toggle}> Annulla </Button>
      	    	  <Button color="success" onClick={this.confirm}> Salva </Button>
      	    	</ModalFooter>
      	   </Modal>
				</span>
      </React.Fragment>
    )
  }
};

export default SchemaSettings;
