import ApiService from "../classes/Api";
import AuthService from "../classes/Auth";

export default class AdministrationApiService {

	static addUserPermission(user_id, permission) {
		return ApiService.addUserPermission(user_id, permission);
	}

	static removeUserPermission(user_id, permission) {
		return ApiService.removeUserPermission(user_id, permission);
	}

	static removeAllUserPermissions(user_id) {
		return ApiService.removeAllUserPermissions(user_id);
	}



	static createTopology(topology) {
		return ApiService.createTopology(topology)
	}

	static editTopology(oid, topology) {
		return ApiService.editTopology(oid, topology)
	}

	static addTopologyField(oid, field) {
		return ApiService.addTopologyField(oid, field)
	}

	static removeTopologyField(oid, key) {
		return ApiService.removeTopologyField(oid, key)
	}


	static createPipeline(name, steps, onFail) {
		return ApiService.createPipeline({ name, steps });
	}


	static createTrigger(trigger) {
		return ApiService.createTrigger( trigger )
	}

	static runTrigger(trigger) {
		return ApiService.runTrigger(trigger);
	}

}