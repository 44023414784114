import React from "react";
import Row from "reactstrap/es/Row";
import Col from "reactstrap/es/Col";
import {Redirect, Route, Switch} from "react-router-dom";
import routes from "routes.js";
import "assets/css/webide.css";
import Nav from "reactstrap/es/Nav";

export default class IDE extends React.Component {

	getRoutes = routes => {
		return routes.map((prop, key) => {
			if (prop.layout === "/ide") {
				return (
					<Route
						path={prop.layout + prop.path}
						component={prop.component}
						key={key}
						exact
					/>
				);
			} else {
				return null;
			}
		});
	};

	render() {
		console.log(this.props.match.params)
		return (
			<>
				<Row>
					<Col md={12} style={{background: "#202125", padding: "1em 2em", boxShadow: "0px 0px 5px #000", zIndex: "100", color: "#fff"}}>Web IDE</Col>
					<Col md={3} xl={2} style={{height: "100vh", margin: 0, padding: 0, overflow: "scroll"}}>

					</Col>
					<Col md={9} xl={10} style={{height: "100vh", margin: 0, padding: 0, overflow: "hidden"}}>
						<Switch>
							{this.getRoutes(routes)}
							<Redirect from="*" to="/admin/index" />
						</Switch>
					</Col>
				</Row>
			</>
		)
	}

}