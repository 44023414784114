import React from "react";

import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip
} from "reactstrap";

import ReactTable from "react-table";

import Header from "components/Headers/Header.js";
import ApiService from "../services/classes/Api";
import DataTableComponent from "../components/Tables/DataTable";
import moment from 'moment';
import {Button, Switch} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import CreateRecordDialog from "../components/Dialogs/CreateRecordDialog";
import Loading from "../components/General/Loading";
import {Slide, toast} from "react-toastify";

class Fornitori extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      templates: []
    }
    this.createTemplate = this.createTemplate.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    ApiService.graph(`
      {
        topology(name: "template") { _id name plural_label label shape states }  
        topologies { _id name plural_label label shape states }
        records(type: "template") { _id state data createdAt }  
      }
    `).then(response => {
      if(response.status === 200)
        this.setState({ templates: response.data.data.records, topology: response.data.data.topology, topologies: response.data.data.topologies });
      else
        alert("error");
    }).catch(e => alert("error"))
  }

  createTemplate(data) {
    ApiService.createRecord("template", data, "new").then(response => {
      toast("Template creato correttamente.", {
        transition: Slide,
        closeButton: true,
        autoClose: 2000,
        position: 'bottom-center',
        type: 'success'
      });
      this.loadData();
    }).catch(e => {
      console.log(e)
      toast("Si è verificato un problema, riprova più tardi.", {
        transition: Slide,
        closeButton: true,
        autoClose: 2000,
        position: 'bottom-center',
        type: 'error'
      });
    });
  }

  editLead(oid) {
    window.location.href = "/admin/templates/" + oid
  }

  render() {

    if(!this.state.templates || !this.state.topology) {
      return <Loading />
    }

    const { templates, topology, topologies } = this.state;

    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <DataTableComponent
                title={"Lista Templates"}
                actions={
                  <>
                    <CreateRecordDialog confirmCallback={(data) => this.createTemplate(data)} topology={topology} topologies={topologies} button={<Button color={"success"} style={{background: "#2dce89", color: "#fff"}} variant={"contained"}startIcon={<FontAwesomeIcon icon={faPlus} size="1x" />} >Nuovo Template</Button>} />
                  </>
                }
                data={templates}
                onClick={(r) => this.editLead(r)}
                schema={[
                  {
                    name: "Nome",
                    selector: (r) => r.data.name
                  },
                  {
                    name: "Codice Referral",
                    selector: (r) => r.data.ref
                  },
                  {
                    name: "Creato il",
                    selector: (r) => moment(parseInt(r.createdAt)).format("DD/MM/YYYY, HH:mm:ss")
                  }
                ]}
              />
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default Fornitori;
