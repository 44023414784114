import React, {Component} from "react";
import Validator from "../../helpers/Validations";
import {Card, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import RecordDataForm from "../General/RecordDataForm";
import {Button} from "@material-ui/core";

export default class CreateRecordDialog extends Component {

	state = {
		modal: false,
		data: {}
	};

	constructor(props) {
		super(props);

		this.toggle = this.toggle.bind(this);
		this.confirm = this.confirm.bind(this);
		this.isDataValid = this.isDataValid.bind(this);
	}

	toggle() {
		this.setState(previousState => ({modal: !previousState.modal}) )
	}

	isDataValid() {
		const state = "new";
		const { data } = this.state;
		const { topology } = this.props;
		const result = Validator.validate(data, topology, state);
		return result.length <= 0;
	}

	confirm() {
		const { data } = this.state;
		if(this.isDataValid()) {
			this.props.confirmCallback({...data});
			this.toggle();
		}
	}

	render() {

		const { name, label, plural_label } = this.props.topology;

		return (
			<React.Fragment>
				<span className="d-inline-block">
					<span onClick={this.toggle}> {this.props.button}</span>
						<Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
      	    	<ModalHeader toggle={this.toggle}>Crea { label }</ModalHeader>
	    	    	<ModalBody>
								<Card body className="card-shadow-primary border mb-3">
                  <RecordDataForm state={"new"} updateRecordData={(data) => this.setState({data})} topology={this.props.topology} data={{}} topologies={this.props.topologies} />
		  	    	  </Card>
	    	    	</ModalBody>
      	    	<ModalFooter>
      	    	  <Button outline color="danger" className={"border-0 btn-transition"} onClick={this.toggle}> Annulla </Button>
      	    	  <Button color="success" onClick={this.confirm}> Salva </Button>
      	    	</ModalFooter>
      	   </Modal>
				</span>
			</React.Fragment>
		)
	}
};