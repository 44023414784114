import React from "react";
import ApiService from "../../services/classes/Api";
import Validator from "../../helpers/Validations";
import Loading from "./Loading";
import {Button, Col, FormGroup, ListGroup, ListGroupItem, Row} from "reactstrap";
import {
	Card, CardActions, CardContent,
	Checkbox,
	FormControlLabel,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select,
	TextField
} from "@material-ui/core";
import { ControlledEditor } from "@monaco-editor/react";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";

const codeEditorOptions = {
	"acceptSuggestionOnCommitCharacter": true,
	"acceptSuggestionOnEnter": "on",
	"accessibilitySupport": "auto",
	"autoIndent": true,
	"automaticLayout": true,
	"codeLens": true,
	"colorDecorators": true,
	"contextmenu": true,
	"cursorBlinking": "blink",
	"cursorSmoothCaretAnimation": false,
	"cursorStyle": "line",
	"disableLayerHinting": false,
	"disableMonospaceOptimizations": false,
	"dragAndDrop": false,
	"fixedOverflowWidgets": false,
	"folding": true,
	"foldingStrategy": "auto",
	"fontLigatures": false,
	"formatOnPaste": false,
	"formatOnType": false,
	"hideCursorInOverviewRuler": false,
	"highlightActiveIndentGuide": true,
	"links": true,
	"mouseWheelZoom": false,
	"multiCursorMergeOverlapping": true,
	"multiCursorModifier": "alt",
	"overviewRulerBorder": true,
	"overviewRulerLanes": 2,
	"quickSuggestions": true,
	"quickSuggestionsDelay": 100,
	"readOnly": false,
	"renderControlCharacters": false,
	"renderFinalNewline": true,
	"renderIndentGuides": true,
	"renderLineHighlight": "all",
	"renderWhitespace": "none",
	"revealHorizontalRightPadding": 30,
	"roundedSelection": true,
	"rulers": [],
	"scrollBeyondLastColumn": 0,
	"scrollBeyondLastLine": false,
	"selectOnLineNumbers": true,
	"selectionClipboard": true,
	"selectionHighlight": true,
	"showFoldingControls": "mouseover",
	"smoothScrolling": false,
	"suggestOnTriggerCharacters": true,
	"wordBasedSuggestions": true,
	"wordSeparators": "~!@#$%^&*()-=+[{]}|;:'\",.<>/?",
	"wordWrap": "off",
	"wordWrapBreakAfterCharacters": "\t})]?|&,;",
	"wordWrapBreakBeforeCharacters": "{([+",
	"wordWrapBreakObtrusiveCharacters": ".",
	"wordWrapColumn": 80,
	"wordWrapMinified": true,
	"wrappingIndent": "none"
};

export default class RecordDataForm extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			data: props.data,
			referenceableRecords: {},
			allLoaded: false
		}
	}

	componentDidMount() {
		this.loadData();
	}

	async loadData() {
		let { topology, topologies } = this.props;
		const fields = Object.keys(topology.shape).map(key => ({...topology.shape[key], key: key}));
		for (let f of fields) {
			if ((f.type === "topology" || f.type === "block_list") && f.references) {
				const referenced = topologies.filter(t => t._id === f.references);
				if (referenced) {
					await this.loadReferenceableRecord(f.key, referenced[0].name);
				}
			}
		}
		this.setState({ allLoaded: true });
	}

	async loadReferenceableRecord(field, referenced) {
		console.log("loadReferenceableRecord", field, referenced)
		return new Promise((resolve, reject) => {
			ApiService.graph(`
			{
				records(type: "${referenced}") {
					_id
     			type
     			state
     			meta
     			data
     			createdAt
     			updatedAt
				}
			}
		`).then(response => {
				if(response.status === 200) {
					let { referenceableRecords } = this.state;
					referenceableRecords[field] = response.data.data.records;
					this.setState({ referenceableRecords });
					resolve();
				}
			})
		});
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if(prevState !== this.state) {
			this.props.updateRecordData(this.state.data);
		}
	}

	setFieldValue(field, value) {
		let { data } = this.state;
		data[field] = value;
		this.setState({ data })
	}

	isFieldDisabled(field) {
		const { topology, state } = this.props;
		return topology.states[state].disableAll;
	}

	validateFieldValue(field, value) {
		const { topology, state } = this.props;
		const result = Validator.validate(this.state.data, topology, state);
		return result.filter(r => r.field === field).length > 0 ? result.filter(r => r.field === field)[0].message : false;
	}

	addToList(field, value) {
		let { data } = this.state;
		data[field] && Array.isArray(data[field]) ? data[field].push(value) : data[field] = [value];
		this.setState( {} )
	}

	_onDragEnd(field, result) {
		if (!result.destination) {
			return;
		}

		const newList = reorder(
			this.state.data[field],
			result.source.index,
			result.destination.index
		);

		let data = this.state.data;
		data[field] = newList;

		this.setState({ data });
	}

	render() {

		if(!this.state.allLoaded)
			return <Loading/>;

		const { data, referenceableRecords } = this.state;
		const { topology: { shape } } = this.props;
		const fields = Object.keys(shape).map(key => ({...shape[key], key}));

		return (
			<Row>
				{fields.map(f => (

					<React.Fragment key={f.key}>

						{
							f.type === "input" ?
								<Col key={f.key} md={6} sm={12}>
									<FormGroup>
										<TextField label={f.label} helperText={this.validateFieldValue(f.key, data[f.key])} disabled={this.isFieldDisabled(f)} value={data[f.key]} onChange={(v) => this.setFieldValue(f.key, v.target.value)} error={this.validateFieldValue(f.key, data[f.key])} fullWidth />
									</FormGroup>
								</Col>
								:
								null
						}

						{
							f.type === "textarea" ?
								<Col key={f.key} md={12} sm={12}>
									<FormGroup>
										<TextField label={f.label} multiline={true} helperText={this.validateFieldValue(f.key, data[f.key])} disabled={this.isFieldDisabled(f)} value={data[f.key]} onChange={(v) => this.setFieldValue(f.key, v.target.value)} error={this.validateFieldValue(f.key, data[f.key])} fullWidth />
									</FormGroup>
								</Col>
								:
								null
						}

						{
							f.type === "checkbox" ?
								<Col key={f.key} md={6} sm={12}>
									<FormControlLabel
										control={
											<Checkbox checked={[true, "s", "y", "on", "1", 1].includes(data[f.key])} disabled={this.isFieldDisabled(f)} helperText={this.validateFieldValue(f.key, data[f.key])} onChange={(v) => this.setFieldValue(f.key, v.target.checked)} value={data[f.key]} />
										}
										label={f.label}
										error={this.validateFieldValue(f.key, data[f.key])}
									/>
									<FormHelperText>{this.validateFieldValue(f.key, data[f.key])}</FormHelperText>
								</Col>
								:
								null
						}

						{
							f.type === "topology" ?
								<Col key={f.key} md={6} sm={12}>
									<FormGroup>
										<InputLabel id="type-label">{f.label}</InputLabel>
										<Select value={data[f.key]} helperText={this.validateFieldValue(f.key, data[f.key])} disabled={this.isFieldDisabled(f)} onChange={(references) => this.setFieldValue(f.key, references.target.value)} fullWidth error={this.validateFieldValue(f.key, data[f.key])} labelId={"type-label"}>
											<MenuItem value={""}>Nessuno</MenuItem>
											{
												referenceableRecords[f.key] ?
													referenceableRecords[f.key].map(f => <MenuItem value={f._id}>{f.data.name}</MenuItem>)
													:
													null
											}
										</Select>
									</FormGroup>
								</Col>
								:
								null
						}

						{
							f.type === "js_code" ?
								<Col key={f.key} md={12} sm={12}>
									<FormGroup>
										<InputLabel id="type-label">{f.label}</InputLabel>
										<ControlledEditor
											height="600px"
											language="javascript"
											value={data[f.key]}
											style={{padding: "1em 0", background: "#1E1E1E"}}
											onChange={(ev, value) => this.setFieldValue(f.key, value)}
											theme={"vs-dark"}
											options={codeEditorOptions}
										/>
									</FormGroup>
								</Col>
							:
								null
						}

						{
							f.type === "json_code" ?
								<Col key={f.key} md={12} sm={12}>
									<FormGroup>
										<InputLabel id="type-label">{f.label}</InputLabel>
										<ControlledEditor
											height="600px"
											language="json"
											style={{padding: "1em 0", background: "#1E1E1E"}}
											value={data[f.key]}
											onChange={(ev, value) => this.setFieldValue(f.key, value)}
											theme={"vs-dark"}
											options={codeEditorOptions}
										/>
									</FormGroup>
								</Col>
								:
								null
						}

						{
							f.type === "html_code" ?
								<Col key={f.key} md={12} sm={12}>
									<FormGroup>
										<InputLabel id="type-label">{f.label}</InputLabel>
										<ControlledEditor
											height="600px"
											language="html"
											style={{padding: "1em 0", background: "#1E1E1E"}}
											value={data[f.key]}
											onChange={(ev, value) => this.setFieldValue(f.key, value)}
											theme={"vs-dark"}
											options={codeEditorOptions}
										/>
									</FormGroup>
								</Col>
								:
								null
						}

						{
							f.type === "css_code" ?
								<Col key={f.key} md={12} sm={12}>
									<FormGroup>
										<InputLabel id="type-label">{f.label}</InputLabel>
										<ControlledEditor
											height="600px"
											language="css"
											style={{padding: "1em 0", background: "#1E1E1E"}}
											value={data[f.key]}
											onChange={(ev, value) => this.setFieldValue(f.key, value)}
											theme={"vs-dark"}
											options={codeEditorOptions}
										/>
									</FormGroup>
								</Col>
								:
								null
						}

						{
							f.type === "block_list" ?
								<Col key={f.key} md={12} sm={12}>
									<Card>
										<CardContent>
											<FormGroup>
												<InputLabel id="type-label">{f.label}</InputLabel>
												<DragAndDropList onDragEnd={(result) => this._onDragEnd(f.key, result)} listType={"block"} blocksList={referenceableRecords[f.key] ? referenceableRecords[f.key] : null} list={data[f.key]} droppableId={f.key} />
											</FormGroup>
										</CardContent>
										<CardActions>
											<Select value={""} onChange={(references) => this.addToList(f.key, references.target.value)} fullWidth error={this.validateFieldValue(f.key, data[f.key])} labelId={"type-label"}>
												{
													referenceableRecords[f.key] ?
														referenceableRecords[f.key].map(f => <MenuItem value={f._id}>{f.data.name}</MenuItem>)
														:
														null
												}
											</Select>
										</CardActions>
									</Card>
								</Col>
								:
								null
						}

					</React.Fragment>


				))}
			</Row>
		);
	}

}

const DragAndDropList = (props) => {

	const { list, droppableId, listType, blocksList } = props;
	const { onDragEnd, removeField } = props;

	return (
		<div>
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId={droppableId}>
					{(provided, snapshot) => (
						<div id={`fieldsEditor_${droppableId}`} {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver, `fieldsEditor_${droppableId}`)}>
							<ListGroup>
								{ list ?
									list.map((key, index) => {

										const html = blocksList.filter(b => b._id === key)[0].data.html;
										const css = blocksList.filter(b => b._id === key)[0].data.css;
										const js = blocksList.filter(b => b._id === key)[0].data.js;

										return (
											<Draggable key={key} draggableId={"draggable_" + key} index={index}>
												{(provided, snapshot) => (
													<div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
														<ListGroupItem style={{padding: 0}}>
															<div className="widget-content p-0">
																{listType === "block" ?
																		<iframe style={{width: "100%"}} src={ApiService.getRenderedBlockLink(key)}></iframe>
																	: null
																}
															</div>
														</ListGroupItem>
													</div>
												)}
											</Draggable>
										)
									})
									:
									<ListGroupItem>
										<div className="widget-content p-0">
											<div className="widget-content-wrapper">
												<div className="widget-content-left">
													<div className="widget-heading">
														Nessun Blocco
													</div>
												</div>
											</div>
										</div>
									</ListGroupItem>
								}
							</ListGroup>
						</div>
					)}
				</Droppable>
			</DragDropContext>
		</div>
	);
};

const getListStyle = (isDraggingOver, droppableId) => {
	return ({
		minHeight: isDraggingOver ? document.getElementById(droppableId).offsetHeight : null
	});
};

const getItemStyle = (isDragging, draggableStyles) => {
	return ({
		...draggableStyles,
		borderLeftColor: isDragging ? '#444054' : 'initial',
		marginBottom: '0.3em'
	})
};

const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};