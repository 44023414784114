import React from 'react';
import cookies from 'react-cookies';

export default class AuthService {

	static state = {
		authenticated: false,
		token: "",
		user: {}
	};

	static getToken() {
		return this.state.token;
	}

	static getUser() {
		return this.state.user;
	}

	static isAuthenticated() {
		return this.state.authenticated;
	}

	static authenticate(token, user) {
		this.state.token = token;
		this.state.user = user;
		this.state.authenticated = true;
		cookies.remove("access_token", {path: "/"});
		cookies.save("access_token", token,{path: "/"});
	}

	static logout() {
		this.state.token = "";
		cookies.remove("access_token", {path: "/"});
		this.state.authenticated = false;
	}

}