import React, {Component} from "react";

// reactstrap components
import {
	Card,
	CardHeader,
	CardBody,
	FormGroup,
	Form,
	Input,
	Container,
	Row,
	Col, Badge
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import GeneralHeader from "../components/Headers/GeneralHeader";
import ApiService from "../services/classes/Api";
import {TextField, Button, Menu, MenuItem, InputLabel, Select, Checkbox, FormControlLabel, FormHelperText} from "@material-ui/core";
import Validator from "../helpers/Validations";
import Loading from "../components/General/Loading";
import {Slide, toast} from "react-toastify";
import moment from "moment";
import RecordDataForm from "../components/General/RecordDataForm";
import swal from "sweetalert";

class EditLead extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			record: {},
			topologies: [],
			validationDropdown: false
		}
	}

	componentDidMount() {
		this.loadData();
	}

	loadData() {
		const { match: { params: { oid } } } = this.props;
		ApiService.graph(`
			{
				record(_id: "${oid}") {
					_id
     			type
     			state
     			meta
     			data
     			versions
     			createdAt
     			updatedAt
				}
				
				topologies {
					_id
					name
				}
			}
		`).then(response => {
			if(response.status === 200) {
				this.setState({
					record: response.data.data.record,
					topologies: response.data.data.topologies
				});
				ApiService.graph(`
					{
						topology(name: "${this.state.record.type}") {
							_id
							name
							shape
							states
						}
					}
				`).then(response => {
					if(response.status === 200) {
						this.setState({
							topology: response.data.data.topology
						});
					}
				});
			}
		});
	}

	setData(data) {
		let { record } = this.state;
		record.data = data;
		this.setState({ record });
	}

	isDataValid() {
		const { state } = this.props;
		const { record, topology } = this.state;
		const result = Validator.validate(record.data, topology, record.state);
		return result.length <= 0;
	}

	saveData() {
		let { record } = this.state;
		if(this.isDataValid()) {
			ApiService.editRecord(record.type, record._id, record.data, record.state).then(response => {
				if(response.status === 200 && response.data.response === "success") {
					toast(`Record modificato correttamente.`, {
						transition: Slide,
						closeButton: true,
						autoClose: 2000,
						position: 'bottom-center',
						type: 'success'
					});
					this.loadData();
				} else {
					toast("Si è verificato un problema, riprova più tardi.", {
						transition: Slide,
						closeButton: true,
						autoClose: 2000,
						position: 'bottom-center',
						type: 'error'
					})
				}
			})
		} else {
			toast("Alcuni campi del form risultano invalidi.", {
				transition: Slide,
				closeButton: true,
				autoClose: 2000,
				position: 'bottom-center',
				type: 'error'
			})
		}
	}

	changeState(state) {
		let { record } = this.state;
		ApiService.setRecordState(record._id, state).then(response => {
			if(response.status === 200 && response.data.response === "success") {
				toast(`Record modificato correttamente.`, {
					transition: Slide,
					closeButton: true,
					autoClose: 2000,
					position: 'bottom-center',
					type: 'success'
				});
				this.loadData();
			} else {
				toast("Si è verificato un problema, riprova più tardi.", {
					transition: Slide,
					closeButton: true,
					autoClose: 2000,
					position: 'bottom-center',
					type: 'error'
				})
			}
		})
	}

	deleteLead() {
		swal({
			title: "Sei sicuro di voler eliminare la lead?",
			text: "L'azione è irreversibile.",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		})
			.then((willDelete) => {
				if (willDelete) {
					ApiService.deleteRecord(this.state.record._id).then(response => {
						if(response.data.response === "success") {
							toast(`Record eliminato correttamente.`, {
								transition: Slide,
								closeButton: true,
								autoClose: 2000,
								position: 'bottom-center',
								type: 'success'
							});
							window.location.href = "/admin/leads";
						}
					})
				}
			});
	}

	render() {

		if(!this.state.record || !this.state.topology) {
			return <Loading />
		}

		const { record: { data, meta, author, createdAt, state, versions }, topology, topologies } = this.state;

		return (
			<>
				<GeneralHeader title={"Modifica Pagina"} />
				<Container className="mt--7" fluid>
					<Row>
						<Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
							<Card className="card-profile shadow" style={{position: "sticky", top: "15px"}}>
								<CardHeader className="text-center border-0 pb-0 pb-md-4">
									<div className="d-flex justify-content-between">
										<div></div>
										<Button
											className="float-right"
											color="secondary"
											onClick={e => this.deleteLead()}
											size="sm"
										>
											Elimina
										</Button>
									</div>
								</CardHeader>
								<CardBody className="pt-0 pt-md-4">
									<Row>
										<div className="col">
											<div className="card-profile-stats d-flex " style={{flexDirection: "column", justifyContent: "flex-start", textAlign: "left"}}>
												<div style={{textAlign: "left"}}>
													<span className="description">Stato</span>
													<span className="heading"><StateBadge state={state} /></span>
												</div>
												<div style={{textAlign: "left"}}>
													<span className="description">Creata il</span>
													<span className="heading">{ moment(parseInt(createdAt)).format("DD/MM/YYYY, HH:mm") }</span>
												</div>
												<div style={{textAlign: "left"}}>
													<span className="description">Revisioni</span>
													<span className="heading">{ versions.length }</span>
												</div>
											</div>
										</div>
									</Row>

									<hr className="my-4" />

									<div className="text-center">
										<StateValidationButton changeState={(state) => this.changeState(state)} />
									</div>

								</CardBody>
							</Card>
						</Col>
						<Col className="order-xl-1" xl="8">
							<Card className="bg-secondary shadow">
								<CardHeader className="bg-white border-0">
									<Row className="align-items-center">
										<Col xs="8">
											<h3 className="mb-0">Pagina</h3>
										</Col>
										<Col className="text-right" xs="4">
											<Button
												color="success"
												href=""
												onClick={e => this.saveData()}
												size="sm"
												style={{background: "#2dce89", color: "#fff"}}
												variant={"contained"}
											>
												Salva
											</Button>
										</Col>
									</Row>
								</CardHeader>
								<CardBody>
									<Form>
										<h6 className="heading-small text-muted mb-4">
											Opzioni principali
										</h6>
										<div className="pl-lg-4">
											<RecordDataForm data={data} topology={topology} state={state} updateRecordData={(data) => this.setData(data)} topologies={topologies} />
										</div>
									</Form>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</>
		);
	}
}

class InjectableData extends Component {

	constructor(props) {
		super(props);
		this.state = {
			data: {}
		}
	}

	render() {

		const { fields } = this.props;

		if(!fields)
			return <></>;

		return(
			<>
				{ fields.map(f => (
					<TextField label={f} value={""} fullWidth />
				)) }
			</>
		)

	}
}

class StateValidationButton extends Component {

	constructor(props) {
		super(props);
		this.state = {
			anchorEl: null
		};

		this.handleClick = this.handleClick.bind(this);
		this.handleClose = this.handleClose.bind(this);
	}

	handleClick(event) {
		this.setState({ anchorEl: event.currentTarget });
	};

	handleClose() {
		this.setState({ anchorEl: null });
	};

	changeState(state) {
		this.props.changeState(state);
	}

	render() {

		const { anchorEl } = this.state;

		return(
			<React.Fragment>
				<Button aria-controls="simple-menu" aria-haspopup="true" onClick={this.handleClick} color={"default"} variant="contained" style={{width: "100%", padding: "0.5em 0", background: "#2dce89", color: "#fff"}}>
					Valida Lead
				</Button>
				<Menu
					id="simple-menu"
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={this.handleClose}
					fullWidth
				>
					<MenuItem onClick={() => this.changeState("new")}>In bozza</MenuItem>
					<MenuItem onClick={() => this.changeState("live")}>Live</MenuItem>
				</Menu>
			</React.Fragment>
		)
	}
}

const StateBadge = (props) => {
	const { state } = props;
	switch(state) {
		case "new":
			return <Badge style={{width: "100%"}} color="info"> In bozza </Badge>;
			break;
		case "live":
			return <Badge style={{width: "100%"}} color={"success"}> Live </Badge>;
		default:
			return <Badge style={{width: "100%"}} color={"secondary"} className={"badge-dot mr-4"}><i className={"bg-secondary"} /> Indefinito </Badge>;
	}
};

export default EditLead;
